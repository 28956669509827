<template>
  <div>
    <NuxtLayout>
      <NuxtPage/>
    </NuxtLayout>
  </div>
</template>

<style lang="scss">
  .layout-enter-active, 
  .layout-leave-active, 
  .profile-layout-enter-active, 
  .profile-layout-leave-active {
    transition: transform 1s;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .search-page-enter-active,
  .search-page-leave-active {
    transition: transform 1s;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: calc(100% - get-vw(212)) !important;
  }

  .layout-enter-active {
    .navbar-container--hide {
      animation: none;
    }
    .navbar--open {
      animation: none;
    }
  }

  .search-page-enter-from,
  .search-page-leave-to {
    transform: translateX(calc(-100% - get-vw(98)));
  }

  .profile-layout-enter-from,
  .profile-layout-leave-to {
    transform: translateX(calc(-100% + get-vw(98)));
  }

  .layout-enter-from,
  .layout-leave-to {
    transform: translateX(calc(100% - get-vw(100)));
  }

  @include tablet {
    .layout-enter-from,
    .layout-leave-to {
      transform: translateX(calc(100% - get-vw(70, 'md')));
    }

    .profile-layout-enter-from,
    .profile-layout-leave-to {
      transform: translateX(calc(-100% + get-vw(66, 'md')));
    }
  }

  @include mobile {
    .layout-enter-from,
    .layout-leave-to {
      transform: none;
    }

    .profile-layout-enter-from,
    .profile-layout-leave-to {
      transform: none;
    }
  }
</style>