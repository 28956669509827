<template lang="pug">
nuxt-layout
  .error-container
    .error
      h1.custom-h1 {{ error.statusCode }}
      h2.custom-h2 {{ statusMessage }}
      nuxt-link.link(to="/") Вернуться на главную страницу
      img(:src="imgUrl")
</template>

<script setup lang="ts">
type Error = {
  url: string
  statusCode: number
  statusMessage: string
  message: string
  description: string
}
const props = defineProps<{
  error: Error
}>()

console.log(props.error)

const imgUrl = computed(() => {
  const { statusCode } = props.error
  if (statusCode === 404) {
    return '/images/error-404.png'
  }
  if (statusCode === 401) {
    return '/images/error-401.png'
  }
  return '/images/error-500.png'
})

const statusMessage = computed(() => {
  const { statusCode } = props.error
  if (statusCode === 404) {
    return 'Увы, но эта страница где-то затерялась в галактике Интернета'
  }
  if (statusCode === 401) {
    return 'Доступ запрещен'
  }

  if (String(statusCode).startsWith('5')) {
    return 'На стороне сервера есть проблема: ' + props.error.message
  }

  return props.error.message
})
</script>

<style lang="scss" scoped>

.error-container {
  display: flex;
  align-items: center;
  max-width: 90vw;
  height: 100vh;
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: get-vw(75);
  margin-bottom: auto;
  margin-top: auto;
  position: absolute;
  left: 0;
  right: 0;
}
.custom-h1 {
  color: #F7ACCB;
  @include headline-h0;
  margin: 0;
}

.custom-h2 {
  @include text-t4-regular;
  margin-top: get-vw(48);
  max-width: 90vw;
  text-align: center;
}

.link {
  color: $links;
  font-family: Raleway;
  font-size: get-vw(23);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  margin-bottom: get-vw(93);
}

img {
    max-width: get-vw(600);
    max-height: get-vw(600);
  }

@include tablet {
  .error {
    margin-top: auto;
    margin-right: get-vw(0, "md");
    justify-content: center;
    margin-bottom: get-vw(200, "md");
  }
  .custom-h1 {
    @include headline-h0("md");
  }
  .custom-h2 {
    @include text-t4-regular-m;
    margin-top: get-vw(48, "md");
    margin-bottom: get-vw(18, "md")
  }
  .link {
    @include text-t4-regular-underlined-m;
    margin-bottom: get-vw(72, "md");
  }
  img {
    max-width: get-vw(600, "md");
    max-height: get-vw(600, "md");
  }
}

@include mobile {
  .error {
    top: get-vw(40, "sm");
    height: calc(100% - get-vw(80, 'sm'));
    margin-top: auto;
    margin-right: 0;
    margin-bottom: get-vw(50, "sm");
  }
  .custom-h1 {
    @include headline-h2-h2("sm");
  }
  .custom-h2 {
    @include text-t4-medium-m("sm");
    margin-top: get-vw(8, "sm");
    margin-bottom: get-vw(8, "sm");
    text-align: center;
    color: $button-xs-text;
    max-width: max-content;
    padding: 0 get-vw(10, 'sm');
  }
  .link {
    @include text-t4-regular-underlined-m("sm");
    margin-bottom: 0;
  }
  img {
    max-width: get-vw(400, "sm");
    max-height: get-vw(400, "sm");
    order: 1;
    margin-bottom: get-vw(8, "sm");
  }

  .link {
    order: 2;
  }

}

</style>
