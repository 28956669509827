import type { DirectiveBinding } from 'vue';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('click-outside', {
        beforeMount(el: HTMLElement, binding: DirectiveBinding, vnode) {
            el.clickOutsideEvent = function(event) {
                if (!(el === event.target || el.contains(event.target))) {
                    binding.value(event);
                }
            };
            document.addEventListener('click', el.clickOutsideEvent);
        },
        updated(el: HTMLElement, binding: DirectiveBinding) {
            if (binding.arg) {
                document.addEventListener('click', el.clickOutsideEvent);
            } else {
                document.removeEventListener('click', el.clickOutsideEvent);
            }
        },
        unmounted(el: HTMLElement) {
            document.removeEventListener('click', el.clickOutsideEvent);
        }
    })
})