import revive_payload_client_4sVQNw7RlN from "/src/code/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/src/code/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/src/code/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/src/code/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/src/code/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/src/code/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/src/code/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/src/code/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/src/code/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/src/code/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_kyZD5zP8xn from "/src/code/node_modules/cb-nuxt-apollo/dist/runtime/plugin.mjs";
import plugin_AOuQ1DYzjk from "/src/code/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import apolloAuth_IWNQtbMXgC from "/src/code/plugins/apolloAuth.ts";
import click_outside_D2NiLGp7dJ from "/src/code/plugins/click-outside.ts";
import error_ldt3PQauZ0 from "/src/code/plugins/error.ts";
import maska_UHaKf2z1iQ from "/src/code/plugins/maska.ts";
import modal_UEFnUXzgTX from "/src/code/plugins/modal.ts";
import position_popup_WVwTRsEwWy from "/src/code/plugins/position-popup.ts";
import vue_virtual_scrolling_lNdpaFlHmk from "/src/code/plugins/vue-virtual-scrolling.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_kyZD5zP8xn,
  plugin_AOuQ1DYzjk,
  apolloAuth_IWNQtbMXgC,
  click_outside_D2NiLGp7dJ,
  error_ldt3PQauZ0,
  maska_UHaKf2z1iQ,
  modal_UEFnUXzgTX,
  position_popup_WVwTRsEwWy,
  vue_virtual_scrolling_lNdpaFlHmk
]