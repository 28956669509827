import { defineNuxtPlugin } from '#app'
import type { DirectiveBinding } from 'vue'

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('modal-open', {
        mounted (el: HTMLElement, binding: DirectiveBinding, vnode) {
            el.addEventListener('click', (event: MouseEvent) => {
                event.preventDefault()
                const modalInstance = vnode.ctx.refs?.[binding.value]
                
                if (modalInstance) {
                    modalInstance.openModal()
                }
            })
        }
    })
})