import { defineStore } from "pinia"

interface SearchState {
  searchHistory: HistoryItem[]
  isFetching?: boolean
}

export type HistoryItem = {
  id: string
  value: string
  isHistory?: boolean
}

interface HistoryResponse {
  id: string
  attributes: {
    text: string
  }
}

export const useSearchStore = defineStore('search', {
  state: (): SearchState => ({
    searchHistory: [],
    isFetching: false
  }),
  actions: {
    async fetchSearcHistory() {
      this.isFetching = true

      const userStore = useUserStore()

      const { fetch } = useGraphQLQuery(gql`
        query ($id: ID!) {
          searchHistories(
            filters: { user: { id: { eq: $id } } }
            pagination: {}
            sort: []
          ) {
            data {
              id
              attributes {
                text
              }
            }
          }
        }`, { id: userStore.id })

      const data = await fetch()

      if (data && data.searchHistories) {
        data.searchHistories.data.forEach((historyText: HistoryResponse) => {
          const exists = this.searchHistory.some(history => history.value === historyText.attributes?.text)

          if (!exists) {
            this.searchHistory.unshift({
              id: historyText.id,
              value: historyText.attributes?.text,
              isHistory: true
            })
          }
        })
        this.isFetching = false
      }
    },
    async createSearchHistory(search: string) {
      const userStore = useUserStore()
      const mutation = gql`
        mutation($user:ID!, $search: String, $date:DateTime){
          createSearchHistory(data: { user: $user, text :$search, publishedAt :$date }){
            data{
              id
              attributes{
                createdAt
              }
            }
          }
        }
      `
      
      const variables = { user: userStore.id, search: search, date: new Date().toISOString() }

      const { fetch } = useGraphQLQuery(mutation, variables)

      const response = await fetch()
    
      if (response && response.createSearchHistory) {
        this.searchHistory.unshift({
          id: response.createSearchHistory.data.id,
          value: search,
          isHistory: true
        })
      }

      return response
    },
    async deleteSearchHistory(id: string | number) {
      const index = this.searchHistory.findIndex(history => history.id === id)

      if (index !== -1) {
        this.searchHistory.splice(index, 1)
      }

      const mutation = gql`
        mutation ($id: ID!) {
          deleteSearchHistory (id: $id){
            data {
              id
              attributes {
                updatedAt
              }
            }
          }
        }
      `
      
      const variables = { id: id }

      const { fetch } = useGraphQLQuery(mutation, variables)

      return fetch()
    }
  }
})