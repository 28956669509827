import validate from "/src/code/node_modules/nuxt/dist/pages/runtime/validate.js";
import route_45global from "/src/code/middleware/route.global.ts";
import manifest_45route_45rule from "/src/code/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  route_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authenticated: () => import("/src/code/middleware/authenticated.ts"),
  oauth: () => import("/src/code/middleware/oauth.ts"),
  auth: () => import("/src/code/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}