export const useRefresh = () => {
  const { signIn, status, data } = useAuth()
  class TokenRefresher {
    private static _instance: TokenRefresher
    private isRefreshing = false
    private currentPromise: Promise<any> | null = null
    private constructor() {}
  
    public static get Instance(): TokenRefresher 
    {
      return this._instance || (this._instance = new this());
    }
  
    public refresh () {
      if (this.isRefreshing && this.currentPromise !== null) {
        return this.currentPromise
      }
      const promise = new Promise<void>((resolve, reject) => {
        this.isRefreshing = true
        // Use signIn from useAuth to refresh the token
        signIn('refresh', {
          redirect: false,
          token: (data.value as any).token,
          refreshToken: (data.value as any).refreshToken
        })
        .then(() => {
          //Update token && retry the request
          this.isRefreshing = false
          resolve()
          // updateToken(status.value, (data.value as any)?.token)
          // .then(() => {
          //   this.isRefreshing = false
          //   resolve()
          // })
        })
        .catch(() => {
          this.isRefreshing = false
          reject()
        })
      })
  
      this.currentPromise = promise
  
      return promise
    }
  }

  const refresher = TokenRefresher.Instance

  return refresher.refresh()
}