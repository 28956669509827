import { defineStore } from "pinia";

type MainState = {
  firstVisit: boolean;
  initialRoute?: string | null;
  afterOAuth?: boolean | null;
  isSearchOpen: boolean;
  isMenuOpen: boolean | null;
  isProfileOpen: boolean;
  isAuthFormOpen: boolean | null;
  isFooterOpen: boolean;
  isHotContentEnabled?: boolean;
  previousPage?: string
  isModalOverlayOpen?: boolean;
}

const SEARCH_DELAY = 200
const AFTER_SEARCH_DELAY = 700
const MENU_AFTER_AUTH_FORM_DELAY = 500
const AUTH_FORM_DELAY = 250

export const useMainStore = defineStore('main', {
  state: (): MainState => {
    const firstVisitCookie = useCookie<boolean>('firstVisit');
    const afterOAuth = useCookie<boolean>('afterOAuth');
    let value = firstVisitCookie.value;
    if (value === undefined) {
      value = true;
      firstVisitCookie.value = true;
    }
    return {
      firstVisit: value,
      initialRoute: null,
      afterOAuth: afterOAuth.value,
      isSearchOpen: false,
      isMenuOpen: null,
      isProfileOpen: false,
      isAuthFormOpen: null,
      isFooterOpen: false,
      isHotContentEnabled: false,
      previousPage: '/',
      isModalOverlayOpen: false
    }
  },
  getters: {
    loggedIn() {
      const { status } = useAuth()
      const userStore = useUserStore()
      if (status.value !== 'authenticated') {
        return false
      }

      if (userStore.registrationComplete === false) {
        return false
      }

      return true
    },
    showOverlay(): boolean {
      return this.isMenuOpen || this.isAuthFormOpen || this.isFooterOpen
    }
  },
  actions: {
    setFirstVisit() {
      this.firstVisit = false;
      const firstVisitCookie = useCookie<boolean>('firstVisit');
      firstVisitCookie.value = false;
    },
    setAuthForm(value: boolean) {
      this.isAuthFormOpen = value
    },
    setInitialRoute(route: string) {
      this.initialRoute = route
    },
    setAfterOAuth(value: boolean | null) {
      const afterOAuth = useCookie<boolean | null>('afterOAuth')
      afterOAuth.value = value
      this.afterOAuth = value
    },
    toggleSearch() {
      if (this.isAuthFormOpen) {
        this.closeAuthForm()

        setTimeout(() => {
          this.isSearchOpen = true;
        }, SEARCH_DELAY)
      } else {
        this.isSearchOpen = !this.isSearchOpen;
      }

      if (this.isSearchOpen && this.isMenuOpen !== null) {
        this.isMenuOpen = false;
      }

    },
    closeSearch() {
      this.isSearchOpen = false;
    },
    toggleMenu() {
      if (this.isSearchOpen) {
        this.isSearchOpen = false;
        setTimeout(() => {
          this.isMenuOpen = true;
        }, AFTER_SEARCH_DELAY); 
      } else if (this.isAuthFormOpen) {
        this.isAuthFormOpen = false;
        setTimeout(() => {
          this.isMenuOpen = true;
          this.isAuthFormOpen = null;
        }, MENU_AFTER_AUTH_FORM_DELAY)
      } else {
        this.isMenuOpen = !this.isMenuOpen;
      }
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    toggleProfile() {
      this.isProfileOpen = !this.isProfileOpen;
    },
    closeProfile() {
      this.isProfileOpen = false;
    },
    toggleAuthForm() {
      if (this.isSearchOpen) {
        this.isSearchOpen = false;
        setTimeout(() => {
          this.isAuthFormOpen = true;
        }, AFTER_SEARCH_DELAY);
      } else if (this.isMenuOpen) {
        this.isMenuOpen = false;
        setTimeout(() => {
          this.isAuthFormOpen = true;
        }, AUTH_FORM_DELAY);
      } else {
        this.isAuthFormOpen = !this.isAuthFormOpen;
      }

      if (!this.isAuthFormOpen) {
        setTimeout(() => {
          this.isAuthFormOpen = null;
        }, MENU_AFTER_AUTH_FORM_DELAY);
      }
    },
    closeAuthForm() {
      if (!this.isAuthFormOpen) return;
      this.isAuthFormOpen = false; 
      setTimeout(() => {
        this.isAuthFormOpen = null;
      }, MENU_AFTER_AUTH_FORM_DELAY);
    },
    toggleFooter() {
      if (this.isSearchOpen) {
        this.isSearchOpen = false;
        setTimeout(() => {
          this.isFooterOpen = true;
        }, AFTER_SEARCH_DELAY);
      } else if (this.isMenuOpen) {
        this.isMenuOpen = false;
        setTimeout(() => {
          this.isFooterOpen = true;
        }, AUTH_FORM_DELAY);
      } else if (this.isAuthFormOpen) {
        this.isAuthFormOpen = false;
        setTimeout(() => {
          this.isAuthFormOpen = null;
          this.isFooterOpen = true;
        }, MENU_AFTER_AUTH_FORM_DELAY)
      } else {
        this.isFooterOpen = !this.isFooterOpen;
      }
    },
    closeFooter() {
      this.isFooterOpen = false;
    },
    setHotContentEnabled() {
      this.isHotContentEnabled = true;
    },
    setPreviousPage(page: string) {
      this.previousPage = page
    },
    toggleModalOverlay() {
      this.isModalOverlayOpen = !this.isModalOverlayOpen;
    },
    closeModalOverlay() {
      this.isModalOverlayOpen = false;
    },
    setMenuOpenToNull() {
      this.isMenuOpen = null;
    }
  }
})
